/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

/*$('.nav .menu-item').first().addClass('active');

var sections = $('.scroll2'),
  nav = $('nav'),
  nav_height = $('.banner').outerHeight();
 
$(window).on('scroll', function () {
  var cur_pos = $(this).scrollTop();
 
  sections.each(function() {
    var top = $(this).offset().top - nav_height,
        bottom = top + $(this).outerHeight();
 
    if (cur_pos >= top && cur_pos <= bottom) {
      nav.find('a').parent().removeClass('active');
      sections.removeClass('active');
 
      $(this).parent().addClass('active');
      nav.find('a[href="#'+$(this).attr('id')+'"]').parent().addClass('active');
    }
  });
});

nav.find('a').on('click', function () {
  var $el = $(this),
  id = $el.attr('href');
 
  $('html, body').animate({
    scrollTop: $(id).offset().top - nav_height
  }, 500, 'swing', function () {
          window.location.hash = id;
      });
 
  return false;
});

$(window).scroll(function() {
   if($(window).scrollTop() + $(window).height() === $(document).height()) {
       $('.nav li').removeClass('active');
       $('.nav .menu-item').last().addClass('active');
   }
});

$('.collapse .nav li a').click(function() {
  $('.collapse').removeClass('in');
  $('.collapse').height(1);
});
*/

nav_height = $('.banner').outerHeight();

$('nav').find('a').on('click', function () {
  var $el = $(this),
  id = $el.attr('href');
 
  $('html, body').animate({
    scrollTop: $(id).offset().top - nav_height
  }, 500, 'swing', function () {
          window.location.hash = id;
      });
 
  return false;
});

$('.collapse .nav li a').click(function() {
  $('.collapse').removeClass('in');
  $('.collapse').height(1);
});

$('.content a[href^="#"]:not(.open-popup-link)').on('click',function (e) {
      e.preventDefault();


      var target = this.hash;
      var $target = $(target);

      nav_height = $('.banner').outerHeight();

      $('html, body').stop().animate({
          'scrollTop': $target.offset().top - nav_height
      }, 900, 'swing', function () {
          window.location.hash = target;
      });
  });

var randomthis = Math.floor((Math.random()* $('.slider-home .slide').length )+1);

$('.slider-impressie').slick({
  dots: false,
  arrows: true,
  infinite: true,
  speed: 300,
  autoplaySpeed: 5000,
  easing: 'swing',
  variableWidth: true,
  focusOnSelect: true,
  centerMode: true,
  initialSlide: randomthis

  
  });

$('.open-popup-link').magnificPopup({
  type:'inline',
  gallery:{
    enabled:true
  }
});

  $('.slider-home').slick({
    autoplay: true,
    arrows: true,
    dots: false,
    autoplaySpeed: 7000,
    easing: 'swing',
    speed: 900,
    pauseOnHover: false
  }); 





      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.


